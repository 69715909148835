import { GoogleAuthProvider, sendSignInLinkToEmail, signInWithPopup, UserCredential } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db, provider, redirectURL } from "./firebase-service";

const actionCodeSettings = {
  url: redirectURL,
  handleCodeInApp: true
};

const loginWithEmailLink = async (email: string) => {
  // console.log("Using email: " + email);
  // console.log("Using redirect: " + redirectURL);

  // console.log(auth);
  // console.log(actionCodeSettings);

  await sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    localStorage.setItem("emailForSignIn", email);
  }).catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error(error);

    console.error(errorCode + ": " + errorMessage);
  });
}

const loginWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    // The signed-in user info.
    await saveSessionToFirestore(result); // Make sure this function also returns a promise if it's async

    // IdP data available using getAdditionalUserInfo(result)
    // ... additional code if needed
  } catch (error: any) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData?.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);

    // Handle errors accordingly
    console.error(`Error during Google login: ${errorCode} - ${errorMessage}`, { email, credential });
    return false;
  }
  return true;
}

const saveSessionToFirestore = async (cred: UserCredential) => {
  if (cred.user.uid === "") {
    console.error("User ID in credential is empty!");
    return
  }

  const userDocRef = doc(db, 'users', cred.user.uid);

  let displayName = cred.user.displayName

  if (displayName == null) {
    displayName = "SaaS User"
  }

  // Save user to user collection
  try {
    const docSnapshot = await getDoc(userDocRef);

    if (!docSnapshot.exists()) {
      // If the document doesn't exist, add the user to Firestore
      await setDoc(userDocRef, {
        email: cred.user.email,
        name: displayName,
        uid: cred.user.uid,
        createdAt: new Date(),
        allowance: cred.user.email?.endsWith("@blueskeye.com") ? 100 : 0, // Give all BLUESKEYE employees 100 credits on signup
        customerID: "",
        allowanceUpdated: 0
      });
      // console.log("New user added to Firestore successfully");
    } else {
      // console.log("User already exists in Firestore");
    }
  } catch (error) {
    console.error("Error checking or adding user to Firestore:", error);
  }
}

export { actionCodeSettings, saveSessionToFirestore, loginWithEmailLink, loginWithGoogle };

